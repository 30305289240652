import { createContext } from "react";
import type { Userflow } from "userflow.js";

interface UserFlowContextValue {
  userflow: Userflow;
  isReady: true;
}

interface UserFlowContextDefaultValue {
  userflow: null;
  isReady: false;
}

export type UserflowContextType =
  | UserFlowContextValue
  | UserFlowContextDefaultValue;

const UserflowContext = createContext<UserflowContextType>({
  userflow: null,
  isReady: false,
});
export default UserflowContext;

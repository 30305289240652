/* eslint-disable @typescript-eslint/ban-ts-comment */
/* NOTE: _this shouldn't_ be undefined further down the chain */
import type { Auth0ContextInterface } from "@auth0/auth0-react";
import { createContext } from "react";

export interface AuthenticationContextValue extends Auth0ContextInterface {}

export const AuthenticationContext =
  // @ts-ignore
  createContext<Auth0ContextInterface>(undefined);

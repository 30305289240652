import type { User } from "@auth0/auth0-react";
import type { RedirectLoginOptions } from "@auth0/auth0-spa-js";
import type React from "react";
import { useEffect, useState } from "react";

import { useLDClient } from "launchdarkly-react-client-sdk";
import { parseCookie } from "../../helpers";
import { useAuthentication } from "../../hooks/useAuthentication";
import { identifyUserLD } from "../FeatureFlag";
import { PageLoader } from "../PageLoader";

// NOTE: This is almost copied exactly from `withAuthenticationRequired` from auth0-react

const defaultOnRedirecting = () => <PageLoader />;

/**
 * @ignore
 */
const defaultReturnTo = () =>
  `${window.location.pathname}${window.location.search}`;

export interface RequireAuthOptions {
  /**
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string);
  /**
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element;
  /**
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: RedirectLoginOptions;
  /**
   * Check the user object for JWT claims and return a boolean indicating
   * whether or not they are authorized to view the component.
   */
  claimCheck?: (claims?: User) => boolean;
  /**
   * If true, will identify the user with LaunchDarkly as soon as the user is authenticated. This is primarily useful only for the cloud auth migration rollout so that we can
   * feature flag calling /me.
   */
  requireSetLDUser?: boolean;
}

export const RequireAuth = ({
  children,
  options = {},
}: {
  children: React.ReactNode;
  options?: RequireAuthOptions;
}) => {
  const {
    returnTo = defaultReturnTo,
    onRedirecting = defaultOnRedirecting,
    loginOptions = {},
    claimCheck = () => true,
    requireSetLDUser = false,
  } = options;

  const { user, isAuthenticated, isLoading, loginWithRedirect } =
    useAuthentication();
  const ldClient = useLDClient();
  const [hasIdentifiedLDUser, setHasIdentifiedLDUser] = useState(
    !requireSetLDUser,
  );

  const hasCypressToken = !!parseCookie("e2e")?.token;
  /**
   * The route is authenticated if the user has valid auth and there are no
   * JWT claim mismatches.
   */
  const routeIsAuthenticated =
    hasCypressToken || (isAuthenticated && claimCheck(user));

  useEffect(() => {
    if (isLoading || routeIsAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
      },
    };
    (async () => {
      await loginWithRedirect(opts);
    })();
  }, [
    isLoading,
    routeIsAuthenticated,
    loginWithRedirect,
    loginOptions,
    returnTo,
  ]);

  // We need to identify the user as soon as we have them so that subsequent flag evaluations have at least the email.
  // Once we get user details and a network, it'll re-identify and evaluate flags again.
  useEffect(() => {
    if (!requireSetLDUser) return;

    if (!(routeIsAuthenticated && user) || !ldClient) {
      return;
    }

    identifyUserLD(ldClient, {
      userId: user.sub!,
      email: user.email!,
    })!
      .catch((error) => console.error("Error identifying user", error))
      .then(() => setHasIdentifiedLDUser(true));
  }, [ldClient, requireSetLDUser, routeIsAuthenticated, user]);

  return routeIsAuthenticated && user && hasIdentifiedLDUser ? (
    <>{children}</>
  ) : (
    onRedirecting()
  );
};

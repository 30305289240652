import type * as React from "react";
import userflow from "userflow.js";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import UserflowContext from "../../context/UserflowContext";
import { useUserDetails } from "../../hooks/useUserDetails";

export const UserflowProvider = ({
  children,
  clientKey,
}: {
  children: React.ReactNode;
  clientKey: string;
}) => {
  const navigate = useNavigate();
  // When our environment has a userflow client key, we should consider userflow to be active. In development, we most likely won't have this set.
  // This should be reserved for staging + production environments.
  const shouldUseUserFlow = Boolean(clientKey);

  const { data: userDetails } = useUserDetails();

  const result = useQuery({
    queryKey: ["userflow", userDetails?.userId, clientKey, userDetails.userId],
    queryFn: async () => {
      userflow.init(clientKey);
      userflow.setResourceCenterLauncherHidden(true);
      await userflow.identify(userDetails.userId, {
        viewport_width: window?.innerWidth,
        viewport_height: window?.innerHeight,
      });
      userflow.setCustomNavigate((url) => navigate(url));
      return null;
    },
    enabled: Boolean(userDetails?.userId && shouldUseUserFlow),
    staleTime: Number.POSITIVE_INFINITY,
    gcTime: Number.POSITIVE_INFINITY,
  });

  return shouldUseUserFlow && result.isSuccess ? (
    <UserflowContext.Provider value={{ isReady: result.isSuccess, userflow }}>
      {children}
    </UserflowContext.Provider>
  ) : (
    <>{children}</>
  );
};

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useUserDetails } from "../../hooks/useUserDetails";
import { useApiHeaders } from "../../utils";

/**
 *
 * @remarks Many of our components and utils rely on having user details be available. This enforces that this is the case
 * before mounting the rest of the app to prevent potential race conditions and undefined property errors
 */
export const UserDetailsHydrator = ({
  children,
  requireSetNetworkIdHeader = false,
}: {
  children: React.ReactNode;
  requireSetNetworkIdHeader?: boolean;
}) => {
  const { set: setHeader } = useApiHeaders();
  const { id: networkId } = useParams();
  const [hasParsedNetworkId, setHasParsedNetworkId] = useState(false);

  const enabled = hasParsedNetworkId || !requireSetNetworkIdHeader;
  const result = useUserDetails({
    enabled,
  });

  useEffect(() => {
    if (!requireSetNetworkIdHeader) return;

    if (networkId) {
      setHeader("x-network-id", networkId);
    }
    setHasParsedNetworkId(true);
  }, [networkId, setHeader, requireSetNetworkIdHeader, hasParsedNetworkId]);

  if (requireSetNetworkIdHeader && !hasParsedNetworkId) return null;

  if (!result.isSuccess || result.isLoading) return null;

  return <>{children}</>;
};

/**
 * If either :// or // is present consider it to be an absolute url
 *
 * @param url string
 */

export function isAbsoluteUrl(url: string) {
  return new RegExp(`(^|:)//`).test(url);
}

const withoutTrailingSlash = (url: string) => url.replace(/\/$/, "");
const withoutLeadingSlash = (url: string) => url.replace(/^\//, "");

export function joinUrls(
  base: string | undefined,
  url: string | undefined,
): string {
  if (!base) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return url!;
  }
  if (!url) {
    return base;
  }

  if (isAbsoluteUrl(url)) {
    return url;
  }

  base = withoutTrailingSlash(base);
  url = withoutLeadingSlash(url);

  return `${base}/${url}`;
}

export async function unwrap<Response>(prom: any): Promise<Response> {
  const result = await prom;

  if (result.error) {
    throw result.error;
  }

  return result.data;
}

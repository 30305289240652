import {
  Badge,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import type { ReactNode } from "react";

import { StordEmblemAndLogo } from "../StordLogo";

import clouds from "./clouds.png";
import { FaIcon } from "../FaIcon";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

type ErrorPageProps = {
  badgeText?: string;
  title: string;
  description: string | ReactNode;
  secondaryButton?: ReactNode;
  primaryButton?: ReactNode;
  outsideNavigation: boolean;
};
export const ErrorPage = ({
  badgeText,
  title,
  description,
  secondaryButton,
  primaryButton,
  outsideNavigation,
}: ErrorPageProps) => {
  const backgroundColor = useColorModeValue("white", "black");
  const logoColor = useColorModeValue("black", "white");
  const badgeColors = useColorModeValue(
    { bgColor: "gray.900", color: "white" },
    { bgColor: "gray.100", color: "black" },
  );

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height={outsideNavigation ? "100vh" : "100%"}
      width={outsideNavigation ? "100vw" : "100%"}
      backgroundImage={`url(${clouds})`}
      backgroundSize="cover"
      backgroundColor={outsideNavigation ? backgroundColor : "bg-surface"}
      direction="column"
      boxShadow={outsideNavigation ? undefined : "base"}
      borderRadius={outsideNavigation ? undefined : "base"}
      gap={4}
      px={4}
    >
      {outsideNavigation ? (
        <StordEmblemAndLogo
          flexProps={{ position: "absolute", top: "min(8%, 80px)" }}
          fill={logoColor}
          widthInPx={140}
        />
      ) : null}
      {badgeText ? (
        <Badge size="xl" {...badgeColors}>
          {badgeText}
        </Badge>
      ) : null}
      <Text
        as="h1"
        textStyle="title"
        fontSize="30px"
        lineHeight="40px"
        fontWeight={700}
        textAlign="center"
      >
        {title}
      </Text>
      <Box maxW="480px" textAlign="center">
        {typeof description === "string" ? (
          <Text>{description}</Text>
        ) : (
          description
        )}
      </Box>
      <Flex gap={2} mt={2}>
        {secondaryButton}
        {primaryButton}
      </Flex>
    </Flex>
  );
};

export const GenericErrorPage = (props: Partial<ErrorPageProps>) => {
  const navigate = useNavigate();

  return (
    <ErrorPage
      badgeText="Unknown error"
      title="We're having trouble loading this page"
      description=""
      secondaryButton={
        <Button
          variant="outline"
          colorScheme="gray"
          leftIcon={<FaIcon icon={faArrowLeft} />}
          onClick={() => navigate(-1)}
        >
          Go back
        </Button>
      }
      outsideNavigation={false}
      {...props}
    />
  );
};

import { useQuery } from "@tanstack/react-query";
import type { UseQueryResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useDatadogRUM } from "../../components/Datadog/DatadogProvider";
import { can } from "../../components/Authorization/authUtils";
import { useAuthentication } from "../useAuthentication";
import { useJsonApi } from "../../utils/createApi";

import type { UserDetails } from "./types";

type GetUserDetailsResponse = { data: { attributes: UserDetails } };
type UseUserDetailsReturn = UseQueryResult<UserDetails, unknown> & {
  data: UserDetails;
};

// @refactor: This is only for the OMS
/**
 * Pulls the details for the current logged in user
 * @remarks The properties on this return are _guaranteed_ to be present. If we fail to load a user, the app will not load.
 */
export const useUserDetails = (
  {
    enabled,
  }: {
    enabled: boolean;
  } = { enabled: true },
): UseUserDetailsReturn => {
  const api = useJsonApi();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthentication();
  const datadogRum = useDatadogRUM();

  const query = useQuery({
    queryKey: ["UserDetails"],
    queryFn: async () => {
      const result = await api.get<GetUserDetailsResponse>({
        url: "/v1/userdetails",
      });
      return result.data.attributes;
    },
    select: (user) => {
      return {
        ...user,
        isAdmin: can(user, "app.admin.view", [
          "11111111-1111-1111-1111-111111111111",
        ]),
      };
    },
    enabled: isAuthenticated && enabled,
    staleTime: 5 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
  }) as UseUserDetailsReturn;

  useEffect(() => {
    if (query.status === "error") {
      navigate("/auth_error");
    }
  }, [navigate, query.status]);

  useEffect(() => {
    if (query.data) {
      const fullname = `${query.data.firstName} ${query.data.lastName}`;

      datadogRum?.setUser({
        id: query.data.userId,
        email: query.data.email,
        name: fullname,
        companyId: query.data.companyId,
        companyTenantVersion: query.data.activeTenantId ? 2 : 1,
      });
    }
  }, [datadogRum, query.data]);

  return query;
};

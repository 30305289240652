import * as React from "react";
import type { AsyncProviderConfig } from "launchdarkly-react-client-sdk";
import {
  asyncWithLDProvider,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useAuth0 } from "@auth0/auth0-react";

import { PageLoader } from "../PageLoader";
import { useAuthentication } from "../../hooks/useAuthentication";

interface CommonProps {
  fallback?: React.ReactNode;
  children: React.ReactNode;
  clientId: string;
  bypassLDInitialization?: boolean;
}

export interface FeatureFlagProviderProps extends CommonProps {
  overrides?: Overrides;
}

type Overrides = Record<string, any>;
type ContextType = { overrides: Overrides };

const FeatureFlagContext = React.createContext({
  overrides: {},
} as ContextType);

const LDProvider = ({ children, clientId }: CommonProps) => {
  // NOTE: Would be wild to type this to where it works later on. It's suppose to be a component, in state.
  const [LDProvider, setLDProvider] = React.useState<any>(null);
  const [config, setConfig] = React.useState<AsyncProviderConfig | null>(null);
  const { user: auth0User } = useAuth0();
  const { isAuthenticated } = useAuthentication();

  const canSetUserDetails = auth0User && isAuthenticated;

  React.useEffect(() => {
    clientId &&
      setConfig({
        clientSideID: clientId,
        options: {
          sendEvents: !(window as any).Cypress,
        },
      } as AsyncProviderConfig);
  }, [clientId]);

  React.useEffect(() => {
    config &&
      !LDProvider &&
      asyncWithLDProvider({
        ...config,
        ...(canSetUserDetails
          ? {
              context: {
                kind: "user",
                key: auth0User?.sub,
                email: auth0User?.email,
              },
            }
          : {
              context: {
                kind: "user",
                key: "not_authenticated",
              },
            }),
      })
        .then((provider) => {
          setLDProvider(() => provider);
        })
        .catch(console.log);
  }, [config, LDProvider, canSetUserDetails, auth0User]);

  return LDProvider ? <LDProvider>{children}</LDProvider> : <>{children}</>;
};

const LDInitializer = ({
  fallback = <PageLoader />,
  children,
  bypassLDInitialization = false,
}: Omit<CommonProps, "clientId">) => {
  const [initialized, setInitialized] = React.useState(false);
  const ldClient = useLDClient();

  React.useEffect(() => {
    if (bypassLDInitialization || !ldClient) return;
    ldClient
      .waitForInitialization()
      .catch(console.log)
      .finally(() => setInitialized(true));
  }, [ldClient, bypassLDInitialization]);

  return initialized || bypassLDInitialization ? (
    <>{children}</>
  ) : (
    <>{fallback}</>
  );
};

export const FeatureFlagProvider = ({
  fallback,
  children,
  bypassLDInitialization,
  clientId,
  overrides = {},
}: FeatureFlagProviderProps) => (
  <FeatureFlagContext.Provider value={{ overrides }}>
    <LDProvider clientId={clientId}>
      <LDInitializer {...{ fallback, bypassLDInitialization }}>
        {children}
      </LDInitializer>
    </LDProvider>
  </FeatureFlagContext.Provider>
);

export const useFeatureFlagOverrides = () => {
  const context = React.useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error(
      "useFeatureFlagOverrides must be used within a FeatureFlagProvider",
    );
  }
  return context.overrides;
};

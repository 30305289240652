import { useLDClient } from "launchdarkly-react-client-sdk";

import { useFeatureFlagOverrides } from "./FeatureFlagProvider";

export const useFlag = <T = any>(flag: string, defaultValue?: T): T => {
  const client = useLDClient();
  const overrides = useFeatureFlagOverrides();

  return (
    overrides[flag] ??
    (client ? client.variation(flag, defaultValue) : defaultValue)
  );
};

import { Button, Link, Text } from "@chakra-ui/react";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "../FaIcon";

import { ErrorPage } from "./ErrorPage";

export const ErrorBoundaryPage = () => (
  <ErrorPage
    title="Hmmm..."
    description={
      <Text>
        Our system seems to be having some trouble.{" "}
        <Link href="https://status.stord.com/">Check our status page</Link> to
        see if we&apos;re experiencing an outage or{" "}
        <Link href="https://helpdesk.stord.com/">contact support</Link> and
        we&apos;ll see how we can help.
      </Text>
    }
    primaryButton={
      <Button
        leftIcon={<FaIcon icon={faArrowsRotate} />}
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    }
    outsideNavigation
  />
);

import type { SetupWorkerApi } from "msw";
import { Fragment, Suspense } from "react";
import { createRoot } from "react-dom/client";

import { PageLoader } from "@stordco/fe-components";

import { App } from "./components/App";

// Turbo will often publish a cached preview environment, so check the hostname to see if we're in a preview or dev environment
const isDevOrPreview =
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("pages.dev");

const prepareWorker = async () => {
  if (isDevOrPreview) {
    const { MSWToolbar } = await import("@stordco/msw-toolbar");
    const { setupWorker } = await import("msw");
    const {
      cancelOrderLines,
      facilities,
      companies,
      networks,
      inventory,
      users,
      migrateUsers,
      createShipper,
      timezones,
      reasonCodes,
    } = await import("./handlers");
    const worker = setupWorker(
      ...[
        cancelOrderLines,
        facilities,
        companies,
        networks,
        inventory,
        users,
        migrateUsers,
        createShipper,
        timezones,
        reasonCodes,
      ].flat(),
    );

    (window as any).worker = worker;

    return { worker, MSWToolbar };
  }
  return { worker: null, MSWToolbar: Fragment };
};

const container = document.getElementById("root");
const root = createRoot(container!);

async function renderApp({
  worker,
  MSWToolbar,
}: {
  worker: SetupWorkerApi;
  MSWToolbar: any;
}) {
  root.render(
    <Suspense fallback={<PageLoader />}>
      <MSWToolbar
        worker={worker}
        isEnabled={isDevOrPreview}
        apiUrl={process.env.NODE_ENV === "dev" ? "/api" : null}
        prefix="admin"
        position="bottom"
        style={{ zIndex: 9999 }}
      >
        <App />
      </MSWToolbar>
    </Suspense>,
  );
}

prepareWorker().then(({ worker, MSWToolbar }) => {
  renderApp({ worker: worker!, MSWToolbar });
});

import type { LDClient } from "launchdarkly-js-client-sdk";

import type { UserDetails } from "../../hooks/useUserDetails";

export const identifyUserLD = async (
  ldClient: LDClient,
  userDetails: Partial<UserDetails> &
    Required<Pick<UserDetails, "userId" | "email">>,
  additionalAttrs = {},
) => {
  if (!ldClient || !userDetails) return;

  // we extract the contexts from the user details because we don't want to send giant objects to LD that aren't relevant.
  // for reference, contexts are typically just user permissions that don't impact feature flags
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { contexts, ...user } = userDetails;

  await ldClient.identify({
    kind: "user",
    key: userDetails.userId,
    ...user,
    ...additionalAttrs,
  });
};

import type { ReactNode } from "react";
import { useQuery } from "@tanstack/react-query";

import { useCloudApi, useConfig } from "@stordco/fe-components";

const redirectTo = (url: string): null => {
  window.location.assign(url);
  return null;
};

export const CloudAuthHydrator = ({ children }: { children: ReactNode }) => {
  const { SHIPPER_HOST } = useConfig();

  const cloudApi = useCloudApi();

  const meQuery = useQuery({
    queryKey: ["CloudMe"],
    queryFn: () => {
      return cloudApi.get<MeResponse>({
        url: "/v1/me",
        params: {
          "filter[app_aliases][]": ["oms_admin"],
        },
      });
    },
  });

  if (!meQuery.isSuccess || meQuery.isLoading) return null;

  const hasAdminApp = meQuery.data?.data.organizations.some((org) =>
    org.apps.some((app) => app.alias === "oms_admin"),
  );
  if (!hasAdminApp) {
    return redirectTo(SHIPPER_HOST);
  }

  return <>{children}</>;
};

interface MeResponse {
  data: Data;
}

interface Data {
  organizations: Organization[];
  type: string;
  user: User;
}

interface User {
  email: string;
  external_id: string;
  first_name: string;
  id: string;
  last_name: string;
  phone?: any;
  status: string;
}

interface Organization {
  alias: string;
  apps: App[];
  description?: any;
  id: string;
  name: string;
}

interface App {
  alias: string;
  base_url: string;
  description?: any;
  id: string;
  name: string;
  realms: Realm[];
}

interface Realm {
  description?: any;
  full_resource_name: string;
  name: string;
  resource_id: string;
  resource_path: string;
}

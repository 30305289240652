import type { UserContextRole, UserDetails } from "../../hooks/useUserDetails";

const isStordUser = (email: string) => email.endsWith("@stord.com");

const hasGlobalContext = (contexts: UserContextRole[] = []): boolean =>
  contexts.some(({ type }) => type === "global");

/**
 * A function that can help determine if a user has a permission for a given context identifier.
 *
 * @example
 * ```ts
 * const canDelete = can(userDetails, "documentmanager.document.delete", [
 *    distributionNetwork?.companyId,
 *    distributionNetwork?.id,
 *  ]);
 * ```
 */

export const can = (
  { email = "", contexts = [] } = {} as UserDetails,
  targetPermission: string,
  targetContextIdList: Array<string> = [],
): boolean => {
  if (isStordUser(email) || hasGlobalContext(contexts)) {
    return true;
  }

  return targetContextIdList.some((contextId) => {
    return contexts
      ?.filter(({ id }) => id === contextId)
      .some(({ permissions }) => permissions.includes(targetPermission));
  });
};

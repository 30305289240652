import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import type { FC } from "react";
import { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import {
  ApiProvider,
  AuthenticationProvider,
  AuthorizationBoundary,
  ConfigProvider,
  AppearanceModeController,
  DatadogProvider,
  ErrorBoundaryReloadOnDynamicImport,
  FeatureFlagProvider,
  MaintenanceModeGate,
  NotFoundErrorPage,
  PageLoader,
  RequireAuth,
  SEO,
  StordChakraProvider,
  useConfig,
  UserDetailsHydrator,
  UserflowProvider,
} from "@stordco/fe-components";
import { theme } from "@stordco/oms-theme";

import { Navigation } from "./navigation";
import { CloudAuthHydrator } from "./CloudAuthHydrator";

const FlatFileIndex = lazy(() => import("../features/FlatFileIndex"));
const ReasonCodes = lazy(
  () => import("../features/SystemSettings/ReasonCodes/ReasonCodes"),
);
const FacilityDetails = lazy(() => import("../features/FacilityDetails"));
const FacilityIndex = lazy(
  () => import("../features/Facilities/FacilityIndex"),
);
const ShippersIndex = lazy(() => import("../features/Shippers/ShippersIndex"));
const Configuration = lazy(
  () => import("../features/Configuration/Configuration"),
);
const BaseNetworkConfiguration = lazy(() =>
  import("../features/Configuration/network/NetworkConfiguration").then(
    ({ BaseNetworkConfiguration }) => ({ default: BaseNetworkConfiguration }),
  ),
);
const AddOnNetworkConfiguration = lazy(() =>
  import("../features/Configuration/network/NetworkConfiguration").then(
    ({ AddOnNetworkConfiguration }) => ({ default: AddOnNetworkConfiguration }),
  ),
);
const Channels = lazy(() =>
  import("../features/Configuration/channels/Channels").then(
    ({ Channels }) => ({
      default: Channels,
    }),
  ),
);
const SupportTasks = lazy(() =>
  import("../features/SupportTasks").then(({ SupportTasks }) => ({
    default: SupportTasks,
  })),
);
const InventorySupportTasks = lazy(() =>
  import("../features/SupportTasks").then(({ InventorySupportTasks }) => ({
    default: InventorySupportTasks,
  })),
);
const ProductCatalogSupportTasks = lazy(() =>
  import("../features/SupportTasks").then(({ ProductCatalogSupportTasks }) => ({
    default: ProductCatalogSupportTasks,
  })),
);
const OrderSupportTasks = lazy(() =>
  import("../features/SupportTasks").then(({ OrderSupportTasks }) => ({
    default: OrderSupportTasks,
  })),
);
const DetailsPanel = lazy(() =>
  import("../features/FacilityDetails/panels").then(({ DetailsPanel }) => ({
    default: DetailsPanel,
  })),
);
const ContactsPanel = lazy(() =>
  import("../features/FacilityDetails/panels").then(({ ContactsPanel }) => ({
    default: ContactsPanel,
  })),
);
const ShippersPanel = lazy(() =>
  import("../features/FacilityDetails/panels").then(({ ShippersPanel }) => ({
    default: ShippersPanel,
  })),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ErrorElement: FC = function ErrorElement() {
  throw useRouteError();
};

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorElement />,
      children: [
        {
          index: true,
          element: <Navigate to="/shippers?filter[status]=active" replace />,
        },
        {
          path: "shippers",
          children: [
            { index: true, element: <ShippersIndex /> },
            {
              path: ":network_id",
              children: [
                {
                  path: "configuration",
                  element: <Configuration />,
                  children: [
                    { index: true, element: <BaseNetworkConfiguration /> },
                    { path: "add-on", element: <AddOnNetworkConfiguration /> },
                    { path: "channels", element: <Channels /> },
                  ],
                },
                {
                  path: "support",
                  element: <SupportTasks />,
                  children: [
                    { path: "inventory", element: <InventorySupportTasks /> },
                    {
                      path: "product_catalog",
                      element: <ProductCatalogSupportTasks />,
                    },
                    { path: "orders", element: <OrderSupportTasks /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "facilities",
          children: [
            { index: true, element: <FacilityIndex /> },
            {
              path: ":facilityId",
              element: <FacilityDetails />,
              children: [
                { index: true, element: <DetailsPanel /> },
                { path: "shippers", element: <ShippersPanel /> },
                { path: "contacts", element: <ContactsPanel /> },
              ],
            },
          ],
        },
        {
          path: "settings",
          children: [{ path: "reason-codes", element: <ReasonCodes /> }],
        },
        {
          path: "tools",
          children: [{ path: "file/upload", element: <FlatFileIndex /> }],
        },
        {
          path: "*",
          element: <NotFoundErrorPage homeUrl="/" />,
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);

export function App() {
  return (
    <StordChakraProvider theme={theme} resetCSS>
      <ErrorBoundaryReloadOnDynamicImport>
        <Suspense fallback={<PageLoader />}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider>
                {(config) => (
                  <DatadogProvider
                    version={__APP_VERSION__}
                    applicationId={config.DATADOG_APPLICATION_ID}
                    clientToken={config.DATADOG_CLIENT_TOKEN}
                    service={config.DATADOG_SERVICE_NAME}
                    env={config.DATADOG_ENV}
                  >
                    <SEO
                      titleTemplate="%s | Stord Admin"
                      defaultTitle="Stord Admin"
                    />

                    <RouterProvider router={router} />
                  </DatadogProvider>
                )}
              </ConfigProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </Suspense>
      </ErrorBoundaryReloadOnDynamicImport>
    </StordChakraProvider>
  );
}

function Root() {
  const config = useConfig();

  return (
    <AuthenticationProvider
      auth0Domain={config.AUTH0_DOMAIN}
      auth0ClientId={config.AUTH0_CLIENT_ID_FE}
      auth0Audience={config.AUTH0_AUDIENCE}
    >
      <RequireAuth>
        <ApiProvider baseUrl={config.API_HOST} cloudUrl={config.CLOUD_HOST}>
          <FeatureFlagProvider
            clientId={config.LD_CLIENT_ID}
            bypassLDInitialization={Boolean(config.BYPASS_LD_INITIALIZATION)}
            overrides={config.FEATURE_FLAG_OVERRIDES}
          >
            <CloudAuthHydrator>
              <UserDetailsHydrator requireSetNetworkIdHeader={false}>
                <UserflowProvider clientKey={config.USERFLOW_CLIENT_KEY}>
                  <MaintenanceModeGate>
                    <AuthorizationBoundary requiredPermission="admin.app.view">
                      <Navigation>
                        <AppearanceModeController />
                        <Suspense fallback={<PageLoader />}>
                          <Outlet />
                        </Suspense>
                      </Navigation>
                    </AuthorizationBoundary>
                  </MaintenanceModeGate>
                </UserflowProvider>
              </UserDetailsHydrator>
            </CloudAuthHydrator>
          </FeatureFlagProvider>
        </ApiProvider>
      </RequireAuth>
    </AuthenticationProvider>
  );
}

import type * as React from "react";
import { Navigate } from "react-router-dom";

import { useUserDetails } from "../../hooks/useUserDetails";
import { PageLoader } from "../PageLoader";
import { useConfig } from "../../hooks/useConfig";

import { can } from "./authUtils";

export type AuthorizationBoundaryProps = {
  children: React.ReactNode;
  requiredPermission: string;
  requiredContextIds?: Array<string>;
};

// @refactor this should basically drop all the loading states and be a simple component that takes an `onUnauthorized` prop and let the consumer handle the redirect. at the point this would render, you'd have a hydrated user and any relevant data. ultimately, this abstraction probably isn't necessary in a component library.
export const AuthorizationBoundary = ({
  requiredPermission,
  requiredContextIds,
  children,
}: AuthorizationBoundaryProps) => {
  const { isLoading, data: userDetails } = useUserDetails();
  const { SHIPPER_HOST } = useConfig();

  const redirectTo = (url: string): null => {
    window.location.assign(url);
    return null;
  };

  /** if we can't redirect, show the loader */
  if (!SHIPPER_HOST) return <PageLoader />;

  if (!userDetails && isLoading) {
    return <PageLoader />;
  }

  if (can(userDetails, requiredPermission, requiredContextIds)) {
    return <>{children}</>;
  }

  // change here to push to appropriate route based on context
  // either shipper or warehouse portal
  if (requiredPermission == "admin.app.view") {
    return redirectTo(SHIPPER_HOST);
  }

  return <Navigate to="/forbidden" />;
};

import type { ReactNode } from "react";
import {
  faBuildings,
  faCode,
  faFileImport,
  faGear,
  faWarehouse,
} from "@fortawesome/pro-regular-svg-icons";

import {
  NavigationProvider,
  LayoutWrapper,
  useUserDetails,
  useAuthentication,
  useFlag,
} from "@stordco/fe-components";

export const Navigation = ({ children }: { children: ReactNode }) => {
  const client = useAuthentication();
  const { data } = useUserDetails();

  const onLogout = () => {
    client.logout({ returnTo: window.location.origin });
  };

  const hideV1Flag = useFlag("hide-v1-elements");

  return (
    <NavigationProvider
      userMenu={{
        onLogout,
        showOperations: false,
        userDisplayName: `${data.firstName} ${data.lastName}`,
      }}
      links={{
        primary: [
          {
            label: "Shippers",
            icon: faBuildings,
            to: "/shippers?filter[status]=active&version=v2",
          },
          {
            label: "Facilities",
            icon: faWarehouse,
            to: "/facilities",
          },
          {
            label: "Tools & settings",
            icon: faGear,
            children: [
              ...(hideV1Flag
                ? []
                : [
                    {
                      label: "Flatfile portal",
                      icon: faFileImport,
                      to: "/tools/file/upload",
                    },
                  ]),
              {
                label: "Reason codes",
                to: "/settings/reason-codes",
                icon: faCode,
              },
            ],
          },
        ],
        secondary: [],
      }}
    >
      <LayoutWrapper>{children}</LayoutWrapper>
    </NavigationProvider>
  );
};

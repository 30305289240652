import { useContext } from "react";

import type { AuthenticationContextValue } from "../context";
import { AuthenticationContext } from "../context";

/**
 * @remarks This hook is now just a wrapper around auth0-react's `useAuth0` hook. If you need access to the current user,
 * please use `useUserDetails()` instead. The `user` details that this hook returns is not what you're looking for.
 */
export const useAuthentication = (): AuthenticationContextValue =>
  useContext(AuthenticationContext);
